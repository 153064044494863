import { v4 } from "uuid"
import { useTranslation } from "react-i18next"

import { CLOSED, addStyle, makeWeekNodes } from "funcs/helpers/doctorTimetable"

const DoctorCell = ({ am, pm }) => {
  const { t } = useTranslation()
  if (am === CLOSED) {
    // 全休
    return <span className="skyblue">{t("休", "休")}</span>
  }

  return (
    <>
      <li className="ampm">{addStyle(am)}</li>
      <li className="day_border"></li>
      <li className="ampm"> {addStyle(pm)} </li>
    </>
  )
}

const HeaderWeekTableHead = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr className="week">
        <th>{t("月", "月")}</th>
        <th>{t("火", "火")}</th>
        <th>{t("水", "水")}</th>
        <th>{t("木", "木")}</th>
        <th>{t("金", "金")}</th>
        <th>{t("土", "土")}</th>
        <th>{t("日", "日")}</th>
      </tr>
      <tr className="ampm">
        <td>
          <li className="ampm">{t("午前", "午前")}</li>
          <li className="day_border"></li>
          <li className="ampm">{t("午後", "午後")}</li>
        </td>
        <td>
          <li className="ampm">{t("午前", "午前")}</li>
          <li className="day_border"></li>
          <li className="ampm">{t("午後", "午後")}</li>
        </td>
        <td>
          <li className="ampm">{t("午前", "午前")}</li>
          <li className="day_border"></li>
          <li className="ampm">{t("午後", "午後")}</li>
        </td>
        <td>
          <li className="ampm">{t("午前", "午前")}</li>
          <li className="day_border"></li>
          <li className="ampm">{t("午後", "午後")}</li>
        </td>
        <td>
          <li className="ampm">{t("午前", "午前")}</li>
          <li className="day_border"></li>
          <li className="ampm">{t("午後", "午後")}</li>
        </td>
        <td>
          <li className="ampm">
            {t("午前", "午前")}
            <br />
            <span className="skyblue">{t("のみ", "のみ")}</span>
          </li>
          <li className="day_border"></li>
          <li className="ampm">
            <span className="skyblue">{t("休", "休")}</span>
          </li>
        </td>
        <td>
          <span className="skyblue">{t("休", "休")}</span>
        </td>
      </tr>
    </thead>
  )
}

/**
 * １行目に日にちと２行目に予定で構成される　週単位のコンポーネント
 *
 * 月初には空のセルが入る場合がある
 * data [{node: }, {node: }, {node: }]
 */
const WeekRow = ({ data }) => {
  return (
    <>
      <tr className="day" key={v4()}>
        {data.map(({ node }) => (
          <td key={v4()}>{node?.day || ""}</td>
        ))}
      </tr>
      <tr key={v4()}>
        {data.map(({ node }) =>
          node?.day ? (
            <td key={v4()}>
              <DoctorCell am={node.am} pm={node.pm} />
            </td>
          ) : (
            <td key={v4()}></td>
          ),
        )}
      </tr>
    </>
  )
}

/**
 *
 * 月曜日始まりのテーブル
 * data １日からの
 * data: [{ node: {...} },...] の配列
 */
export const PcMonthTable = ({ data }) => {
  const weeks = makeWeekNodes(data)

  return (
    <div className="pc">
      <table>
        <HeaderWeekTableHead />
        <tbody>
          {weeks.map(week => (
            <WeekRow key={v4()} data={week} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
