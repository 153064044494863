import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

export const HoursArea = ({ isInviewfadeInUp = false }) => {
  const { t } = useTranslation()

  return (
    <div className={`hours_area ${isInviewfadeInUp && "inviewfadeInUp"}`}>
      <div className="container2">
        <Link to="/appointment_menu/about_appointment">
          <h2>
            {t("診療時間と受付時間のご案内", "診療時間と受付時間のご案内")}
          </h2>
        </Link>
        <div className="top">
          <div
            className={`general_box ${isInviewfadeInUp && "inviewfadeInUp"}`}
          >
            <div
              className={`general_part ${isInviewfadeInUp && "inviewfadeInUp"}`}
            >
              <div className="schedule_area">
                <div className="pc">
                  <table>
                    <tbody>
                      <tr>
                        <th className="tit">{t("診療時間/受付時間")}</th>
                        <th>{t("月", "月")}</th>
                        <th>{t("火", "火")}</th>
                        <th>{t("水", "水")}</th>
                        <th>{t("木", "木")}</th>
                        <th>{t("金", "金")}</th>
                        <th>{t("土", "土")}</th>
                      </tr>
                      <tr>
                        <td>8:30 - 12:00</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                      </tr>
                      <tr>
                        <td>13:30 - 17:30</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mo">
                  <table>
                    <tbody>
                      <tr>
                        <th className="tit"></th>
                        <td>8:30 -12:00</td>
                        <td>13:30-17:30</td>
                        <td>18:00-19:00</td>
                      </tr>
                      <tr>
                        <th>{t("月", "月")}</th>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                      <tr>
                        <th>{t("火", "火")}</th>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                      <tr>
                        <th>{t("水", "水")}</th>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                      <tr>
                        <th>{t("木", "木")}</th>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                      <tr>
                        <th>{t("金", "金")}</th>
                        <td>〇</td>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                      <tr>
                        <th>{t("土", "土")}</th>
                        <td>〇</td>
                        <td>{t("終了", "終了")}</td>
                        <td>{t("終了", "終了")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
